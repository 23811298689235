.page_wrapper {
  @apply flex justify-between flex-col-reverse md:flex-row gap-10;
  h3 {
    @apply text-xl font-bold;
  }
}

.stats_boxes {
  @apply flex justify-between items-start flex-col md:flex-row gap-4 w-full;
}

.title_bar_earnings {
  @apply flex justify-start md:justify-between items-start md:items-center flex-col md:flex-row pb-4;
}