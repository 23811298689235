.welcome_wrapper {
  @apply bg-gray-50 flex items-center justify-center;
}

.onboarding_header {
  @apply w-full flex justify-start pl-10 gap-12 items-center md:justify-between md:px-5 px-2 py-3 bg-jc-primary overflow-x-hidden;
  h4 {
    @apply text-white md:text-xl font-bold;
  }
  .close_container {
    @apply flex cursor-pointer justify-start gap-2 items-center hover:text-white bg-white px-4 py-1 rounded-full hover:bg-opacity-25 hover:shadow-md ease-in-out duration-200;
  }
  .head_name_container {
    @extend .close_container;
    @apply bg-jc-primary;
  }
}

.onboard_main_wrapper {
  @apply flex flex-col-reverse md:flex-row gap-5 min-h-full p-5 w-full md:px-24;
}
.steps_nav_container {
  @apply p-5 rounded-md md:w-4/12 bg-gray-100 w-full;
}

.step_form_wrapper {
  @apply w-full p-5 bg-gray-50 rounded-md shadow transition-all ease-linear duration-200;
}