body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
  color: #3c4356;
  overflow-x: hidden;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #d0d0d0 #fff;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #d0d0d0;
  border-radius: 20px;
  border: 3px solid #fff;
}

.ant-table-body::-webkit-scrollbar {
  display: none;
}

.spin-button-none::-webkit-outer-spin-button,
.spin-button-none::-webkit-inner-spin-button {
  appearance: none;
}

label {
  @apply my-3 block mb-2 text-sm font-medium text-gray-700;
}

h4 {
  @apply font-bold text-gray-600 mb-2;
}

h2 {
  @apply font-bold text-gray-600 text-2xl;
}

.ant-checkbox-wrapper {
  span {
    @apply text-gray-500;
  }
}

.form_inputs {
  @apply space-y-5;
}

.PhoneInput {
  @apply border border-gray-200 rounded;
  .PhoneInputCountry {
    @apply px-2 border-r-2 border-r-gray-200;
    padding-top: 1rem;
    padding-bottom: 0.8rem;
  }

  input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    outline: none;
  }
}

.PhoneInput:focus {
  outline: none;
}

.ant-card-meta-title {
  @apply text-2xl font-bold;
}

.terms_wrapper {
  @apply flex flex-col overflow-scroll;
  h1 {
    @apply font-bold text-2xl;
  }

  span {
    @apply text-gray-500 font-medium underline-offset-2;
  }
}

.ant-btn-primary {
  background-color: #2d4669;
  border: none;
}

.footer_buttons_container {
  @apply flex justify-end gap-4 flex-wrap;
}

.button_container {
  @apply w-full;
  max-width: 200px;

}

.ant-alert-message {
  text-transform: capitalize;
  font-weight: bold;
}

.ant-table-row-level-0 {
  .ant-table-cell {
    text-transform: capitalize;
  }
}

.section_title, .ant-card-head-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.welcome_text {
  @apply font-bold text-4xl;
}


.chart_title {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}

.chart_value {
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.375rem;
}

//onboarding intro


.onboarding_intro {
  @apply shadow-xl rounded-xl w-full;
  .intro_header {
    @apply text-center mb-10 bg-jc-primary rounded-t-xl py-4;
    span {
      @apply text-gray-200;
    }
  }
  h4 {
    @apply text-2xl font-black text-white;
  }
  .content_box {
    @apply px-12 pb-6;
  }

  ul {
    @apply space-y-4;
  }

  li {
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3;
    .col, strong {
      @apply flex items-center justify-start gap-4 my-2;
    }
    strong {
      @apply text-gray-400;
    }
  }
}