.list_tile {
  @apply text-white my-6 flex items-start justify-around gap-2;
  .list_icon {
    @apply w-6 h-6 mt-1;
  }

  h4 {
    @apply font-bold text-xl mb-2 text-gray-300;
  }

  p {
    @apply font-light text-sm;
  }
}

small {
  @apply text-red-500 italic;
}

.inclined_decoration {
  @apply absolute top-0 left-0 right-0 overflow-x-clip h-screen w-full bg-gray-50 -z-10;
  .incliner_top {
    height: 55%;
  }

  .incliner_container {
    background-color: #fff;
    -webkit-transform: skewY(-13deg);
    -moz-transform: skewY(-13deg);
    -ms-transform: skewY(-13deg);
    transform: skewY(-13deg);
    display: flex;
    justify-content: space-between;
  }

  .left_incliner_decoration {
    position: relative;
    bottom: 20px;
  }

  .left_incliner_decoration > div {
    width: 200px;
    height: 37px;
    opacity: 0.9;
  }

  .left_incliner_decoration > div:nth-child(1) {
    background-color: #62cffc;
    width: 100px;
    position: relative;
    top: 10px;
    z-index: 1;
  }

  .left_incliner_decoration > div:nth-child(2) {
    background-color: #2D4669;
    width: 250px;
    z-index: 50;
  }

  .right_incliner_decoration > div {
    width: 200px;
    height: 37px;
    opacity: 0.9;
  }

  .right_incliner_decoration > div:nth-child(1) {
    background-color: #2D4669;
    width: 250px;
    z-index: 50;
  }
}

._white_box {
  @apply shadow-md bg-white space-y-4 rounded-md w-full;
}

.copyright_container {
  @apply w-full items-center text-center md:text-left text-gray-100 bottom-0;
}

.checkbox {
  @apply w-5 h-5;
}

//for input
.text, .number {
  width: 100%;
  height: 44px;
  font-size: 16px;
  @apply border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
}


.primary_button {
  display: flex;
  height: 2.625rem;
  padding: 0 2.5rem;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  border-radius: 0.75rem;
  background: #2D4669;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  @apply whitespace-nowrap w-full hover:bg-gray-800 hover:border-jc-primary bg-opacity-20 hover:border-2;
}

.primary_button_outlined {
  display: flex;
  height: 2.625rem;
  padding: 0 2.5rem;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  color: #2D4669;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0.75rem;
  border: 2px solid #2D4669;
  @apply whitespace-nowrap w-full hover:bg-jc-primary hover:text-white cursor-pointer;
}

.primary_button_outlined:disabled {
  @apply cursor-not-allowed opacity-25;
}

.disabled_comp {
  @apply cursor-not-allowed opacity-25;
}

.otp_input {
  @apply w-12 h-12 border-2 rounded bg-transparent outline-none text-center font-medium text-xl border-gray-400 focus:border-gray-700 focus:text-gray-700 transition;
}

.title_bar {
  @apply flex gap-4 flex-col;
  p {
    @apply text-gray-500;
  }
}

.validated_field_col {
  small {
    @apply text-red-500 text-xs font-light italic;
  }
}

.row {
  @apply w-full grid items-center;
}

.two_cols_row {
  @apply grid grid-cols-1 md:grid-cols-2 gap-3;
}

.steps_form_head {
  @apply space-y-2 mb-4 bg-white p-3 rounded;
  h4 {
    @apply text-xl font-bold text-gray-700;
  }

  p {
    @apply text-gray-400;
  }
}

.step_form_footer {
  @apply w-full md:w-6/12 flex justify-center items-center gap-4 mt-10;
}

.dashboard_boxed {
  @apply p-3 md:px-32 lg:px-12 ease-linear duration-200;
}

.top_bar_wrapper {
  @extend .dashboard_boxed;
  @apply md:px-16 px-6 flex justify-between items-center;
}

.top_bar_logo_container {
  @apply w-32 h-12 relative right-1;
  img {
    @apply w-full h-full object-cover;
  }
}

.top_bar_icons_container {
  @apply text-gray-400 flex items-center justify-between space-x-4 text-xl;
  span {
    @apply cursor-pointer;
  }
}

.body_container {
  //@extend .dashboard_boxed;
  @apply flex flex-col bg-gray-50 min-h-screen overflow-x-clip;
}

.navigation_menu_container {
  background: var(--blue-primary, #2D4669);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  @apply justify-start items-center gap-12 py-4 hidden px-14 md:flex;
  span {
    @apply rounded-full text-white py-1;
  }

  .active_menu {
    display: flex;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.375rem;
    background: #3C75AA;

    //text
    color: var(--neutral-00, #FFF);
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
}

.small_box {
  @apply p-5 bg-gray-50 rounded-2xl;
  h3 {
    @apply font-medium text-gray-400 mb-4;
  }

  span {
    @apply font-medium text-2xl text-gray-600;
  }
}

.page_title {
  @apply text-2xl font-bold text-gray-500;
}

.table_title_bar {
  @apply flex justify-between items-center my-5;
}

.upload_documents {
  @apply space-y-2;
  h4 {
    @apply text-gray-600 font-bold;
  }
}

.image_upload_section {
  @apply w-full md:max-w-[120px] capitalize block p-1;
}

.image_upload_box {
  @apply border w-16 border-gray-500 h-16 md:w-24 md:h-24 border-dashed rounded bg-white flex items-center justify-center text-3xl text-gray-400 cursor-pointer hover:bg-gray-400 hover:text-white ease-in-out duration-300;
  img {
    @apply h-28 object-cover;
  }
}


.doc_section {
  @apply flex justify-start items-center gap-10 md:gap-5;
  .upload_field {
    @apply text-xs text-red-500;
  }
}

.row_section {
  @apply flex flex-col md:flex-row justify-start gap-20 items-center flex-wrap;
}

.small_image_active {
  @apply rounded-md w-16 h-16 object-contain cursor-pointer hover:rotate-12 hover:scale-110 transition-all ease-in-out duration-200;
}

.small_image_inactive {
  @apply rounded-xl shadow-md border-jc-primary border-4 p-3 bg-sky-100;
}

.Vehicle_row {
  @apply flex justify-start items-center gap-4 my-4 rounded hover:border-r-gray-400 hover:border-r-4 cursor-pointer transition-all duration-200;
  h4 {
    @apply font-bold text-gray-600;
  }

  p {
    @apply text-gray-400;
  }

  img {
    @apply rounded-full w-14 h-14 object-cover;
  }
}

.value_box {
  border-radius: 0.75rem;
  border: 1px solid var(--neutral-200, #E4E4E7);
  background: var(--neutral-00, #FFF);
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .card_title {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    color: var(--neutral-600, #52525A);
  }

  .value {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

.active_Vehicle {
  @apply border-r-4 border-r-jc-primary shadow p-1;
}

.mobileMenu {
  li {
    @apply rounded-full py-2 w-full text-gray-500 px-4;
  }

  .active_menu {
    @apply underline text-jc-secondary underline-offset-4;
  }

  @apply flex flex-col justify-start items-end space-y-6 p-4 transition-all ease-in-out duration-300;
}

.no_result_box {
  @apply text-center text-gray-300 py-20;
  p {
    font-weight: bold;
    font-size: 2em;
  }

  span {
    @apply cursor-pointer text-jc-primary hover:underline;
  }
}

.error_text {
  @apply text-xs text-red-500 italic;
}

.title {
  @apply font-bold;
}

.stats_boxes {
  @apply flex justify-between items-start flex-col md:flex-row gap-4 w-full;
}


.delete_button {
  @apply px-4 py-1 bg-red-700 rounded-md text-white hover:bg-opacity-20 hover:text-red-500;
}

.edit_button {
  @apply px-4 py-1 rounded-md text-white hover:bg-opacity-20 hover:text-sky-500;
  background: #101828;
}

.view_button {
  @apply px-4 py-1 rounded-md border-2 border-jc-primary text-jc-primary hover:bg-opacity-20 hover:text-sky-900;
}

.page_section_title {
  color: var(--gray-900, #101828);
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
}

.title_bar_pages {
  @apply flex justify-start md:justify-between items-start md:items-center flex-col md:flex-row pb-4;
}